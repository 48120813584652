/*
* ink colors (with variations only for black and white)
*/
.footer-wrapper {
  background-color: #F5F5F5;
  position: relative;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px; }
  .footer-wrapper .operational__time {
    background-color: #F5F5F5;
    border-radius: 8px; }
  .footer-wrapper .footer__insurance {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 600px;
    padding: 32px 16px 16px 16px;
    gap: 16px; }
    @media (min-width: 576px) {
      .footer-wrapper .footer__insurance {
        flex-direction: row;
        padding: 40px 0;
        margin: 0 auto;
        width: 1120px;
        gap: 24px;
        flex-wrap: nowrap;
        height: unset; } }
    .footer-wrapper .footer__insurance #one {
      order: 1; }
    .footer-wrapper .footer__insurance #two {
      order: 5; }
      @media (min-width: 576px) {
        .footer-wrapper .footer__insurance #two {
          order: 2; } }
    .footer-wrapper .footer__insurance #four {
      order: 3; }
      @media (min-width: 576px) {
        .footer-wrapper .footer__insurance #four {
          order: 4; } }
    .footer-wrapper .footer__insurance #five {
      order: 4; }
      @media (min-width: 576px) {
        .footer-wrapper .footer__insurance #five {
          order: 5; } }
    .footer-wrapper .footer__insurance .text-blue {
      display: flex;
      color: #1D66DD; }
    .footer-wrapper .footer__insurance--item {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 45%; }
      @media (min-width: 576px) {
        .footer-wrapper .footer__insurance--item {
          width: 20%; } }
  .footer-wrapper .title_bold {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    white-space: pre-wrap;
    color: #222222; }
  .footer-wrapper .subtitle {
    margin-bottom: 6px; }
  .footer-wrapper .margin-top--lg {
    margin-top: 24px; }
  .footer-wrapper .margin-top--sm {
    margin-top: 12px; }
  .footer-wrapper .button-blue {
    color: #1D66DD;
    font-weight: 700;
    font-size: 12px;
    padding: 2px 6px;
    box-sizing: border-box;
    overflow: visible;
    justify-content: center;
    align-items: center;
    width: auto;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #1D66DD;
    cursor: pointer; }
    .footer-wrapper .button-blue:hover {
      background-color: #1D66DD;
      color: #FFFFFF; }
      .footer-wrapper .button-blue:hover img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(21deg) brightness(103%) contrast(102%); }
    .footer-wrapper .button-blue img {
      width: 16px;
      height: 16px;
      padding-right: 2px;
      margin-right: 2px;
      vertical-align: text-top;
      filter: invert(26%) sepia(90%) saturate(1676%) hue-rotate(204deg) brightness(103%) contrast(95%); }
  .footer-wrapper .button__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: left;
    margin: 8px 0 0 0; }
  .footer-wrapper .operational__time {
    padding: 8px 12px;
    margin-top: 12px; }
  .footer-wrapper .operational__schedule-table {
    display: flex;
    flex-direction: row;
    gap: 12px;
    flex-wrap: wrap;
    margin-top: 4px; }
  .footer-wrapper .operational__day {
    border-left: #D6D6D6 1px solid;
    padding-left: 12px; }
  .footer-wrapper .footer__item__info {
    background-color: white;
    padding: 32px 16px;
    margin: 0 auto;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px; }
    @media (min-width: 576px) {
      .footer-wrapper .footer__item__info {
        padding: 40px 0;
        border-top-left-radius: 48px;
        border-top-right-radius: 48px; } }
  .footer-wrapper .footer__item__group {
    background-color: white;
    text-align: left;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    @media (min-width: 576px) {
      .footer-wrapper .footer__item__group {
        flex-direction: row;
        margin: 0 auto;
        width: 1120px; } }
  @media (min-width: 576px) {
    .footer-wrapper .footer__item--one {
      width: 24%; } }
  .footer-wrapper .footer__item--two {
    margin-top: 32px; }
    @media (min-width: 576px) {
      .footer-wrapper .footer__item--two {
        width: 41%;
        margin-top: unset; } }
  .footer-wrapper .footer__item--three {
    margin-top: 32px; }
    @media (min-width: 576px) {
      .footer-wrapper .footer__item--three {
        width: 32%;
        margin-top: unset; } }
  .footer-wrapper .payment-methods-wrapper {
    margin: 8px 0 0 0; }
  .footer-wrapper .footer__copyright {
    text-align: left;
    box-sizing: border-box;
    display: flex;
    background-color: #1855B8; }
    .footer-wrapper .footer__copyright .copyright {
      color: #FFFFFF;
      padding: 16px; }
      @media (min-width: 576px) {
        .footer-wrapper .footer__copyright .copyright {
          padding: 16px 0;
          margin: 0 auto;
          width: 1120px; } }
  .footer-wrapper .ojk-title {
    margin-bottom: 8px; }
